import React, { useEffect, useState } from 'react'
import { TabContext, TabList, TabPanel } from '@material-ui/lab'
import { Box, Checkbox, FormControlLabel, Radio, Tab, Tooltip } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux';
import { getcontracts } from '../../pages/bookingConfirmation/thunks/getcontracts';
import moment from 'moment';
import SpinnerLoader from '../../pages/bookingConfirmation/Utils/spinner';
import { useHistory } from 'react-router-dom';
import { getClassData } from '../thunks/getClassData';
import { setClassData, setSelectedTarifData } from '../AppStore/ActionCreator/commonDataAction';
import { putBulkUpdate } from '../thunks/putBulkUpdate';
import swal from 'sweetalert';

const NewTariff = () => {

    const [disableEdit, setDisableEdit] = useState(true)
    const [classApiCallLoader, setClassApiCallLoader] = useState(false)
    const [classesArray, setClassesArray] = useState([])
    const [value, setValue] = React.useState('1');
    const tarifDetails = useSelector((state)=>state.commonDataReducer.tarifDetail)
    const dispatch = useDispatch();
    const history = useHistory();
    const [tarif, setTarif] = useState({})
    const [updateLoader, setUpdateLoader] = useState(false)

    useEffect(()=>{
        setTarif({...tarifDetails})
    },[tarifDetails])

    const editAll = () => {
        setDisableEdit(!disableEdit)
    }

    const handleChange = async (event, newValue) => {
        setValue(newValue)
        if(newValue == "2"){
            setClassApiCallLoader(true)
            const classes = await getcontracts({"tarifKey": tarif?.tarifKey })
            setClassApiCallLoader(false)
            setClassesArray(classes?.tarifs[0]?.classes)
        }
        
    };

    const cancelClick = () => {
        setDisableEdit(true);
        setTarif({...tarifDetails})
    }

    useEffect(()=>{
        window. scrollTo({ top: 0, left: 0})
    },[])

    const getClasses = (classes = "") => {
        const arr = classes.split(",");
        let addEllipsis = false;
        if(arr.length > 3){
            arr.length = 3;
            addEllipsis = true;
        }
        const returnValue =  arr.join(",");
        return addEllipsis ? `${returnValue}...` : returnValue;
    }

    const openClass = async (id, tarif) => {
        let res = await getClassData(id?.clasKey)
        let tarifData = { ...id, ...tarif }
        dispatch(setSelectedTarifData(tarifData))
        dispatch(setClassData(res))
        history.push("/newClass")
    }

    const commonOnChange = (key, newValue) => {
        setTarif({ ...tarif, [key]: newValue });
    }

    const removeItem = (array, indexToRemove) => {
        return array.filter((item, index) => index !== indexToRemove);
    };

    const saveTariff = async() => {
        setUpdateLoader(true)
        let temp = {...tarif}
        if (tarif?.destinations) {
            temp['destinations'] = tarif.destinations?.split(',')
        }
        const payload = {
            "tarifKeys": tarif?.tarifKey ? [tarif?.tarifKey] : undefined,
            "tarifData" : temp
        }
        const finalTarifData = filterObject(convertPropertiesToArray(payload)?.tarifData, keysToKeep)
        const finalPayload = {...payload,["tarifData"]:finalTarifData}
        const bulkUpdateResponse = await putBulkUpdate(finalPayload)
        setUpdateLoader(false)
        if(bulkUpdateResponse?.data?.message != "Bulk update was successful"){
            swal({
              text:bulkUpdateResponse?.data?.message,
              icon: "error",
              button: "Close",
            })
        }else{
            swal({
                text:"Tarif updated successfully!",
                icon: "success",
                button: "Close",
              })
        }
    }

    function convertPropertiesToArray(jsonData) {
        if (jsonData?.tarifData?.tarifKeys) {
            delete jsonData.tarifData.tarifKeys;
        }
    
        if (jsonData.tarifData.origins !== null && jsonData.tarifData.origins !== undefined && !Array.isArray(jsonData.tarifData.origins)) {
            jsonData.tarifData.origins = [jsonData.tarifData.origins];
        }
    
        if (jsonData.tarifData.destinations !== null && jsonData.tarifData.destinations !== undefined && !Array.isArray(jsonData.tarifData.destinations)) {
            jsonData.tarifData.destinations = [jsonData.tarifData.destinations];
        }
    
        if (jsonData.tarifData.marketingAirline !== null && jsonData.tarifData.marketingAirline !== undefined && !Array.isArray(jsonData.tarifData.marketingAirline)) {
            jsonData.tarifData.marketingAirline = [jsonData.tarifData.marketingAirline];
        }
    
        if (jsonData.tarifData.mappedOpeartingAirlines !== null && jsonData.tarifData.mappedOpeartingAirlines !== undefined && !Array.isArray(jsonData.tarifData.mappedOpeartingAirlines)) {
            jsonData.tarifData.mappedOpeartingAirlines = [jsonData.tarifData.mappedOpeartingAirlines];
        }
    
        return jsonData;
    }
    

    let keysToKeep = [
        "origins",
        "destinations",
        "marketingAirline",
        "mappedOpeartingAirlines",
        "maximumFeeCCBycarrierType",
        "maximumFeeCCAmoutByCarrier",
        "note",
        "ccFeesForTBMerchant",
        "landFare",
        "cruiseFare",
        "cabinType",
        "tripType",
        "isBrandedFare",
        "DFW",
        "funSun",
        "intairTransit",
        "skyLink",
        "sunquest",
        "network",
        "formsOfPayment",
        "notesIV"
    ];

    function filterObject(obj, keys) {
        return keys.reduce((acc, key) => {
            if (obj.hasOwnProperty(key)) {
                acc[key] = obj[key];
            }
            return acc;
        }, {});
    }

    function formatToESTDate(dateString) {
        const date = new Date(dateString);
        const year = date.getUTCFullYear();
        const month = date.getUTCMonth();
        const day = date.getUTCDate();
        const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        return `${day}-${monthNames[month]}-${year}`;
      }


    return (
        <>{Object.keys(tarif).length ? <div>
            <div className="cm-head-2 mb-8">
                <div className="d-flex justify-content-between">
                <span style={{"fontSize":"1rem","color":"#0055b8","cursor":"pointer"}} onClick={()=>{history.push('/Newcontractlist')}}>{`<<- Back to Results`}</span>
                    <span className="cm__Title">Tariff Edit</span>
                    
                    {/* <div className="classTopEdit"> */}
                        {/* <button className='classEditBtn' onClick={editAll}> */}
                            <i className="fas fa-edit" style={{"color":"#0055b8"}} onClick={editAll}></i>
                        {/* </button> */}
                    {/* </div> */}
                </div>
            </div>

            <div className="Tariff__Section">
                <div >
                    <div className="card Tariff__BorderB">
                        <div className="card-body">
                            <div className="row mt-4">
                                <div className="col-md-6 cm-col">

                                    <div className="row">
                                        <div className="col-md-6">

                                            <label className="cm-head-label">Tariff Key</label>
                                            <input
                                                className="form-control cm-input"
                                                placeholder="Enter tarif key"
                                                value={tarif?.tarifKey}
                                                disabled={true}
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <label className="cm-head-label">Validating Carrier</label>
                                            <input
                                                className="form-control cm-input"
                                                placeholder="Enter validating carrier"
                                                value={tarif?.carrier}
                                                disabled={disableEdit}
                                                onChange={(e)=>commonOnChange('carrier',e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 cm-col">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <label className="cm-head-label">Origin</label>

                                            <input
                                                className="form-control cm-input"
                                                placeholder="Enter Origin ..."
                                                value={tarif?.origins}
                                                disabled={disableEdit}
                                                onChange={(e)=>commonOnChange('origins',[e.target.value])}
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <label className="cm-head-label">Destination</label>
                                            <input
                                                className="form-control cm-input"
                                                placeholder="Enter Destination ..."
                                                value={tarif?.destinations}
                                                disabled={disableEdit}
                                                onChange={(e)=>commonOnChange('destinations',[e.target.value])}
                                            />
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div className="row">
                                {/* <div className="col-md-6">
                                    <div className="row">
                                        <div className="col-md-6 cm-col">
                                            <label className="cm-head-label">VIA</label>
                                            <input className="form-control cm-input" placeholder="Enter VIA" />
                                        </div>
                                        <div className="col-md-6 cm-col">
                                            <label className="cm-head-label">Tariff Note</label>
                                            <input
                                                className="form-control cm-input"
                                                placeholder="Enter Tariff Note"
                                            />
                                        </div>
                                    </div>

                                </div> */}

                                <div className="col-md-6">

                                    <div className="row">
                                        <div className="col-md-6 cm-col">
                                            <label className="cm-head-label">Cabin Type</label>
                                            <div className="row">
                                                <div className="col-md-6 ">
                                                    <FormControlLabel control={<Checkbox color="primary" checked={tarif?.cabinType ? tarif?.cabinType.includes("Business") : false} disabled={disableEdit}/>} label="Business" />  </div>
                                                <div className="col-md-6  ">
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                color="primary"
                                                                checked={tarif?.cabinType ? tarif?.cabinType.includes("Economy") : false}
                                                                disabled={disableEdit}
                                                                onChange={(e)=>commonOnChange('cabinType',tarif?.cabinType?.includes("Economy") ? removeItem([...tarif?.cabinType], tarif?.cabinType.indexOf('Economy')) : [...tarif?.cabinType].push('Economy'))}
                                                            />
                                                        }
                                                        label="Economy"
                                                    />
                                                </div>
                                                <div className="col-md-6 ">
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                color="primary"
                                                                checked={tarif?.cabinType ? tarif?.cabinType.includes("PremiumBusiness") : false}
                                                                disabled={disableEdit}
                                                                onChange={(e)=>commonOnChange('cabinType',tarif?.cabinType?.includes("PremiumBusiness") ? removeItem([...tarif?.cabinType], tarif?.cabinType.indexOf('PremiumBusiness')) : [...tarif?.cabinType].push('PremiumBusiness'))}
                                                            />
                                                        }
                                                        label="Premium Business"
                                                    />
                                                </div>
                                                <div className="col-md-6 ">
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                color="primary"
                                                                checked={tarif?.cabinType ? tarif?.cabinType.includes("PremiumEconomy") : false}
                                                                disabled={disableEdit}
                                                                onChange={(e)=>commonOnChange('cabinType',tarif?.cabinType?.includes("PremiumEconomy") ? removeItem([...tarif?.cabinType], tarif?.cabinType.indexOf('PremiumEconomy')) : [...tarif?.cabinType].push('PremiumEconomy'))}
                                                            />
                                                        }
                                                        label="Premium Economy"
                                                    />
                                                </div>
                                                <div className="col-md-6 ">
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                color="primary"
                                                                checked={tarif?.cabinType ? tarif?.cabinType.includes("First") : false}
                                                                disabled={disableEdit}
                                                                onChange={(e)=>commonOnChange('cabinType',tarif?.cabinType?.includes("First") ? removeItem([...tarif?.cabinType], tarif?.cabinType.indexOf('First')) : [...tarif?.cabinType].push('First'))}
                                                            />
                                                        }
                                                        label="First class"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 cm-col">
                                            <label className="cm-head-label">Trip Type</label>
                                            <div className="cm-radio-inline">
                                                <FormControlLabel control={<Radio color="primary" checked={tarif?.tripType == "OW"} disabled={disableEdit} onChange={()=>commonOnChange('tripType',"OW")}/>} label="One Way"
                                                />
                                                <FormControlLabel control={<Radio color="primary" checked={tarif?.tripType == "-OW"} disabled={disableEdit} onChange={()=>commonOnChange('tripType',"-OW")}/>} label="Not One Way"
                                                />{" "}
                                            </div>
                                        </div>

                                    </div>

                                </div>
                                <div className="col-md-6">
                                    <div className="row">
                                        <div className="col-md-6 cm-col">
                                            <label className="cm-head-label">Branded Fare</label>
                                            <div className="cm-radio-inline">
                                                <FormControlLabel control={<Radio color="primary" checked={tarif?.isBrandedFare == 1} disabled={disableEdit} onChange={()=>commonOnChange('isBrandedFare',1)}/>} label="Yes" />
                                                <FormControlLabel control={<Radio color="primary" checked={tarif?.isBrandedFare == 0} disabled={disableEdit} onChange={()=>commonOnChange('isBrandedFare',0)}/>} label="No" />
                                            </div>
                                        </div>
                                        <div className="col-md-6 cm-col">
                                            <label className="cm-head-label">Contract Types</label>
                                            <div className="cm-checkbox-inline">
                                                <FormControlLabel control={<Checkbox checked={tarif?.landFare && tarif?.landFare == "1"} disabled={disableEdit} onChange={()=>commonOnChange('landFare',"1")}/>} label="Land" />
                                                <FormControlLabel control={<Checkbox color="primary" checked={(tarif?.landFare && tarif?.landFare == "0" && tarif?.cruiseFare && tarif?.cruiseFare == "0")} disabled={disableEdit} onChange={()=>{commonOnChange('landFare',"0");commonOnChange('cruiseFare',"0")}}/>} label="Air Only" />
                                                <FormControlLabel control={<Checkbox color="primary" checked={tarif?.cruiseFare && tarif?.cruiseFare == "1"} disabled={disableEdit} onChange={()=>commonOnChange('cruiseFare',"1")}/>} label="Cruise" />

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                    <Box sx={{ width: '100%', typography: 'body1' }}>
                        <TabContext value={value}>
                            <Box sx={{ borderBottom: 20, borderColor: '#C9F7F5', background: '#fff' }}>
                                <TabList onChange={handleChange} aria-label="lab API tabs example">
                                    <Tab label="Tariff" value="1" />
                                    <Tab label="Classes" value="2" />
                                </TabList>
                            </Box>
                            <TabPanel value="1" className='m-0 p-0'>
                                <div className="card Tariff__BorderB">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-md-3">
                                                <div className="cm-col">
                                                    <label className="cm-head-label">Marketing Airline</label>
                                                    <input className="form-control cm-input" type="text" value={tarif?.marketingAirline?.join()} disabled={disableEdit} onChange={(e)=>commonOnChange('marketingAirline',e.target.value)}/>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="cm-col">
                                                    <label className="cm-head-label">Operating Airline</label>
                                                    <input className="form-control cm-input" type="text" value={tarif?.mappedOpeartingAirlines?.join()} disabled={disableEdit} onChange={(e)=>commonOnChange('mappedOpeartingAirlines',e.target.value)}/>
                                                </div>
                                            </div>

                                            <div className="col-md-3">
                                                <div className="cm-col">
                                                    <label className="cm-head-label">Note Phase IV</label>
                                                    <input className="form-control cm-input" type="text" value={tarif?.notesIV} disabled={disableEdit} onChange={(e)=>commonOnChange('notesIV',e.target.value)}/>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="cm-col">
                                                    <label className="cm-head-label">Note </label>
                                                    <input className="form-control cm-input" type="text" value={tarif?.note} disabled={disableEdit} onChange={(e)=>commonOnChange('note',e.target.value)}/>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="cm-col">
                                                    <label className="cm-head-label">Contract Owner</label>
                                                    <input className="form-control cm-input" type="text" value={tarif?.contractOwner} disabled={disableEdit} onChange={(e)=>commonOnChange('contractOwner',e.target.value)}/>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="cm-col">
                                                    <label className="cm-head-label">PAX </label>
                                                    <FormControlLabel control={<Radio color="primary" checked={tarif?.passengerType == "SENIOR" ? true : false} disabled={disableEdit}/>} label="Senior" onChange={()=>commonOnChange('passengerType',"SENIOR")}/>
                                                    <FormControlLabel control={<Radio color="primary" checked={tarif?.passengerType == "ADULT" ? true : false} disabled={disableEdit}/>} label="Adult" onChange={()=>commonOnChange('passengerType',"ADULT")} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="card Tariff__BorderB">
                                    <div className="card-body">
                                        <div className="cm-head-2 mb-4">
                                            <div className="d-flex justify-content-between">
                                                <span>Characteristics</span>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-3">
                                                <div className="cm-col">
                                                    <label className="cm-head-label">FOP allowed (by Airline) for <br />Ticket issuance</label>
                                                    <div className="row">
                                                        <div className="col-md-6 ">
                                                            <FormControlLabel control={<Checkbox color="primary" checked={tarif?.formsOfPayment && tarif?.formsOfPayment?.includes("CHEQUE")} disabled={disableEdit}/>} label="Cheque" onChange={(e)=>commonOnChange('formsOfPayment',tarif?.formsOfPayment?.includes("CHEQUE") ? removeItem([...tarif?.formsOfPayment], tarif?.formsOfPayment.indexOf('CHEQUE')) : [...tarif?.formsOfPayment].push('CHEQUE'))}/>
                                                        </div>
                                                        <div className="col-md-6 ">
                                                            <FormControlLabel control={<Checkbox color="primary" checked={tarif?.formsOfPayment && tarif?.formsOfPayment?.includes("CREDITCARD")} disabled={disableEdit}/>} label="Credit Card" onChange={(e)=>commonOnChange('formsOfPayment',tarif?.formsOfPayment?.includes("CREDITCARD") ? removeItem([...tarif?.formsOfPayment], tarif?.formsOfPayment.indexOf('CREDITCARD')) : [...tarif?.formsOfPayment].push('CREDITCARD'))}/>
                                                        </div>
                                                        <div className="col-md-6 ">
                                                            <FormControlLabel control={<Checkbox color="primary" checked={tarif?.formsOfPayment && tarif?.formsOfPayment?.includes("CREDITINTERNAL")} disabled={disableEdit}/>} label="Credit Internal" onChange={(e)=>commonOnChange('formsOfPayment',tarif?.formsOfPayment?.includes("CREDITINTERNAL") ? removeItem([...tarif?.formsOfPayment], tarif?.formsOfPayment.indexOf('CREDITINTERNAL')) : [...tarif?.formsOfPayment].push('CREDITINTERNAL'))}/>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="cm-col">
                                                    <label className="cm-head-label">Maximum Authorised Amount on<br /> Airline as Merchant </label>
                                                    <div className="row">
                                                        <div className="col-md-4 ">
                                                            <FormControlLabel control={<Radio color="primary" checked={tarif?.maximumFeeCCBycarrierType == "Amount"}/>} label="($)" disabled={disableEdit} onChange={()=>commonOnChange('maximumFeeCCBycarrierType',"Amount")}/>
                                                            <FormControlLabel control={<Radio color="primary" checked={tarif?.maximumFeeCCBycarrierType == "Percentage"}/>} label="(%)" disabled={disableEdit} onChange={()=>commonOnChange('maximumFeeCCBycarrierType',"Percentage")}/>
                                                        </div>
                                                        <div className="col-md-8">
                                                            <input className="form-control cm-input" placeholder="Enter Amount" value={tarif?.maximumFeeCCAmoutByCarrier}disabled={disableEdit} onChange={(e)=>commonOnChange('maximumFeeCCAmoutByCarrier',e.target.value)}/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-3">
                                                <div className="cm-col">
                                                    <label className="cm-head-label">Combination</label>
                                                    <div className="row">
                                                        <div className="col-md-6 ">
                                                            <FormControlLabel control={<Radio color="primary" checked={tarif?.calculationBasis == "AVERAGE"} disabled={disableEdit}/>} label="Average" onChange={()=>commonOnChange('calculationBasis',"AVERAGE")}/>
                                                            <FormControlLabel control={<Radio color="primary" checked={tarif?.calculationBasis == "MAXIMUM"} disabled={disableEdit}/>} label="Maximum" onChange={()=>commonOnChange('calculationBasis',"MAXIMUM")}/>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <FormControlLabel control={<Radio color="primary" checked={tarif?.calculationBasis == "MINIMUM"} disabled={disableEdit}/>} label="Minimum" onChange={()=>commonOnChange('calculationBasis',"MINIMUM")}/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="cm-col">
                                                    <label className="cm-head-label">Credit Card Processing Fees </label>
                                                    <div className="row">
                                                        <div className="col-md-6 ">
                                                            <input className="form-control cm-input" type="text" placeholder="Enter Fees" value={tarif?.ccFeesForTBMerchant} disabled={disableEdit} onChange={(e)=>commonOnChange('ccFeesForTBMerchant',e.target.value)}/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="card Tariff__BorderB">
                                    <div className="card-body">
                                        <div className="cm-head-2 mb-4">
                                            <div className="d-flex justify-content-between">
                                                <span className="">Routings</span>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-3">
                                                <div className="cm-col">
                                                    <label className="cm-head-label">Airport not allowed</label>
                                                    <input className="form-control cm-input" type="text" value={tarif?.disallowedAirports.join()} disabled={disableEdit} onChange={(e)=>commonOnChange('disallowedAirports',[e.target.value])}/>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="cm-col">
                                                    <label className="cm-head-label">Routing field </label>
                                                    <input className="form-control cm-input" type="text" value={tarif?.routings} disabled={disableEdit} onChange={(e)=>commonOnChange('routings',e.target.value)}/>
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="cm-col mt-1">
                                                    <label className="cm-head-label"></label>
                                                    <FormControlLabel control={<Checkbox color="primary" checked={Boolean(tarif?.skipRoutingCheck)} disabled={disableEdit}/>} label="No Routing Validation By Startrek" onChange={()=>commonOnChange('skipRoutingCheck',!Boolean(tarif?.skipRoutingCheck))}/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="card ">
                                    <div className="card-body">
                                        <div className="cm-head-2 mb-4">
                                            <div className="d-flex justify-content-between">
                                                <span className="">Distribution</span>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-3">
                                                <div className="cm-col">
                                                    <label className="cm-head-label">Distribution</label>
                                                    <div className="row">
                                                        <div className="col-md-6"><FormControlLabel control={<Checkbox checked={Boolean(tarif?.skyLink)} disabled={disableEdit}/>} label="Sky Link" onChange={()=>commonOnChange('skyLink',!Boolean(tarif?.skyLink))}/></div>
                                                        <div className="col-md-6"><FormControlLabel control={<Checkbox color="primary" checked={Boolean(tarif?.intairTransit)} disabled={disableEdit}/>} label="Intair Transit" onChange={()=>commonOnChange('intairTransit',!Boolean(tarif?.intairTransit))}/> </div>
                                                        <div className="col-md-6"><FormControlLabel control={<Checkbox checked={Boolean(tarif?.funSun)} disabled={disableEdit}/>} label="Fun Sun" onChange={()=>commonOnChange('funSun',!Boolean(tarif?.funSun))}/></div>
                                                        <div className="col-md-6"><FormControlLabel control={<Checkbox color="primary" checked={Boolean(tarif?.DFW)} disabled={disableEdit}/>} label="DFW" onChange={()=>commonOnChange('DFW',!Boolean(tarif?.DFW))}/></div>
                                                        <div className="col-md-6"><FormControlLabel control={<Checkbox color="primary" checked={Boolean(tarif?.sunquest)} disabled={disableEdit}/>} label="Sunquest" onChange={()=>commonOnChange('sunquest',!Boolean(tarif?.sunquest))}/></div>
                                                        <div className="col-md-6"><FormControlLabel control={<Checkbox color="primary" checked={Boolean(tarif?.network)} disabled={disableEdit}/>} label="Network" onChange={()=>commonOnChange('network',!Boolean(tarif?.network))}/></div>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="cm-col">
                                                    <label className="cm-head-label">Can not be distributed</label>
                                                    <FormControlLabel control={<Checkbox checked={tarif?.distributionChannels && tarif?.distributionChannels.includes("INTERNAL")} disabled={disableEdit}/>} label="Internal Users" onChange={(e)=>commonOnChange('distributionChannels',tarif?.distributionChannels?.includes("INTERNAL") ? removeItem([...tarif?.distributionChannels], tarif?.distributionChannels.indexOf('INTERNAL')) : [...tarif?.distributionChannels].push('INTERNAL'))}/>
                                                    <FormControlLabel control={<Checkbox color="primary" checked={tarif?.distributionChannels && tarif?.distributionChannels.includes("EXTERNAL")} disabled={disableEdit}/>} label="External Users" onChange={(e)=>commonOnChange('distributionChannels',tarif?.distributionChannels?.includes("EXTERNAL") ? removeItem([...tarif?.distributionChannels], tarif?.distributionChannels.indexOf('EXTERNAL')) : [...tarif?.distributionChannels].push('EXTERNAL'))}/>
                                                    <FormControlLabel control={<Checkbox color="primary" checked={tarif?.distributionChannels && tarif?.distributionChannels.includes("XML-B2C")} disabled={disableEdit}/>} label="XML - B2C" onChange={(e)=>commonOnChange('distributionChannels',tarif?.distributionChannels?.includes("XML-B2C") ? removeItem([...tarif?.distributionChannels], tarif?.distributionChannels.indexOf('XML-B2C')) : [...tarif?.distributionChannels].push('XML-B2C'))}/>
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="cm-col">
                                                    <label className="cm-head-label">PCC DU TARIFF</label>
                                                    <div className='row'>
                                                        <div className="col-md-6">
                                                            <label className="cm-head-label">VIT</label>
                                                            <input className="form-control cm-input" type="text" value={tarif?.old_tarfPCC_VIT} disabled={disableEdit} onChange={(e)=>commonOnChange('old_tarfPCC_VIT',e?.target?.value)}/>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <label className="cm-head-label">FUN</label>
                                                            <input className="form-control cm-input" type="text" value={tarif?.tarifPCC_FUN} disabled={disableEdit} onChange={(e)=>commonOnChange('tarifPCC_FUN',e?.target?.value)}/>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <label className="cm-head-label">SKY</label>
                                                            <input className="form-control cm-input" type="text" value={tarif?.tarifPCC_SKY} disabled={disableEdit} onChange={(e)=>commonOnChange('tarifPCC_SKY',e?.target?.value)}/>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <label className="cm-head-label">TC</label>
                                                            <input className="form-control cm-input" type="text" value={tarif?.tarifPCC_TC} disabled={disableEdit} onChange={(e)=>commonOnChange('tarifPCC_TC',e?.target?.value)}/>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <label className="cm-head-label">DFW</label>
                                                            <input className="form-control cm-input" type="text" value={tarif?.tarifPCC_DFW} disabled={disableEdit} onChange={(e)=>commonOnChange('tarifPCC_DFW',e?.target?.value)}/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {disableEdit ? null :<Box className="cm-card-edit" sx={{ background: '#C9F7F5', padding: '16px', mb: '0' }}>
                                {updateLoader ? <SpinnerLoader /> :  <><button className="cm-secondary-btn mr-2" type="button" onClick={cancelClick}>Cancel</button>
                                    <button className="cm-primary-btn" type="button" onClick={()=>saveTariff()}><i className='fa fa-save mr-1'></i> Save</button></>}
                                </Box>}
                            </TabPanel>
                            <TabPanel value="2" className='m-0 p-0'>
                                <div className="card ">
                                    <div className="card-body">

                                        <div className="d-flex justify-content-between">
                                            <button className="cm-yellow-btn mb-5">+ Create New Class</button>
                                        </div>

                                        {classApiCallLoader?<>...Loading</>:<>{classesArray?.map((classValue)=>{return(<div>
                                            <div className="Tariff_classCard__main" style={{"cursor":"pointer"}} onClick={()=>openClass(classValue, tarif)}>
                                                <div className="Tariff_classCard__Left">
                                                    <div className="Tariff_classCard__alert">
                                                        <span>C</span>
                                                    </div>
                                                    <div className="Tariff_classCard__Right">
                                                        <div className='Tariff_classCard_classOfClass'>
                                                            <span className="Tariff_classCard_mainTitle"> Type: </span>
                                                            <span className="Tariff_classCard_mainTitle"> {classValue?.contractType}</span>
                                                        </div>
                                                        <div className='Tariff_classCard_classOfClass'>
                                                            <span className="Tariff_classCard_subTitle">  Classes: </span>
                                                            <Tooltip placement='top' title={classValue?.classCodes.join()}><span className="Tariff_classCard_mainTitle">{getClasses(classValue?.classCodes.join())}</span></Tooltip></div>
                                                        <div className='Tariff_classCard_classOfClass'><span className="Tariff_classCard_subTitle"> Brand: </span>
                                                            <span className="Tariff_classCard_mainTitle">{classValue?.brandCode}</span></div>
                                                        <div className='Tariff_classCard_classOfClass'><span className="Tariff_classCard_subTitle"> Category: </span>
                                                            <span className="Tariff_classCard_mainTitle">{classValue?.category}</span></div>
                                                        <div className='Tariff_classCard_classOfClass'><span className="Tariff_classCard_subTitle"> EDR:</span>
                                                            <span className="Tariff_classCard_mainTitle"> {formatToESTDate(classValue?.classLastTicketingDate)}</span>
                                                        </div><div className='Tariff_classCard_classOfClass'><span className="Tariff_classCard_subTitle">Note: </span>
                                                            <span className="Tariff_classCard_mainTitle">{classValue?.classNote}</span></div>
                                                        <div className='Tariff_classCard_classOfClass'><span className="Tariff_classCard_subTitle"> Routing SRC:</span>
                                                            <span className="Tariff_classCard_mainTitle">  {classValue?.classCodeDesc}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>)})}</>}
                                    </div>
                                </div>
                            </TabPanel>
                        </TabContext>
                    </Box>



                </div>
            </div >
            </div>:null}
        </ >
    )
}

export default NewTariff